import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../../Hooks/AuthProvider";

const AuthRoute = ({callbackURL= "/", permittedRoles = []}) => {
    const user = useAuth();
    if(!user?.data) return <Navigate to={callbackURL} />;
    if(permittedRoles.length && !permittedRoles.includes(user?.role)) return <Navigate to="/Unauthorized" />
    return <Outlet />;
};

export default AuthRoute;