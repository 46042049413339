import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";


/////v2 here
import FMSLoginPage from "./v2/Pages/FMSLogin/index.js";
import FMSContainerPage from "./v2/Pages/FMSContainerPage/index.js";
import FMSDashboardPage from "./v2/Pages/FMSDashboard/index.js";
import FMSVesselPage from "./v2/Pages/FMSVessel/index.js";
import FMSMapPage from "./v2/Pages/FMSMap/index.js";
import FMSMaintenancePage from "./v2/Pages/FMSMaintenance/index.js";
import FMSCrewPage from "./v2/Pages/FMSCrew/index.js";
import FMSInventoryPage from "./v2/Pages/FMSInventory/index.js";
import FMSAnalyticsPage from "./v2/Pages/FMSAnalytics/index.js";
import FMSVesselDetailPage from "./v2/Pages/FMSVessel/VesselDetail/index.js";
import FMSVesselTrackingPage from "./v2/Pages/FMSVessel/VesselTracking/index.js";
import FMSVesselSMSPage from "./v2/Pages/FMSVessel/VesselSMS/index.js";
import FMSEnginePerformancePage from "./v2/Pages/FMSVessel/VesselSMS/EnginePerformance/index.js";

/////v2 FMS Admin
import FMSAdminLogin from "./v2/Admin/Pages/FMSAdminLogin/index.js";
import AdminCustomerPage from "./v2/Admin/Pages/AdminCustomer/index.js";
import AdminProductPage from "./v2/Admin/Pages/AdminProducts/index.js";
import AdminVesselPage from "./v2/Admin/Pages/AdminVessel/index.js";
import AdminContainerPage from "./v2/Admin/Pages/FMSAdminContainerPage/index.js";
import FMSVesselSMSDashboard from "./v2/Pages/FMSVessel/VesselSMSDashboard/index.js";
import FMSContainerPageV2 from "./v2/Pages/FMSContainerPageV2/index.js";
import FMSAuthenticateTokenV2 from "./Components/v2/FMSAuthenticateTokenV2/index.js";
import FMSAdminAuthenticateTokenV2 from "./Components/v2/FMSAdminAuthenticateTokenV2/index.js";
import AdminContainerPageV2 from "./v2/Admin/Pages/FMSAdminContainerPageV2/index.js";
import AuthRoute from "./Router/AuthRoute/index.js";
import RoutePermittedRole from "./Constants/Roles/index.js";
import AuthProvider from "./Hooks/AuthProvider/index.js";
import { Box } from "@mui/material";
export default function App() {
  return (
    <BrowserRouter>
    <AuthProvider>
        <Routes>
          <Route path="/" element={<FMSLoginPage />} />
          {/* Preparation untuk proper single page application system */}
          {/*
          <Route element={<FMSAuthenticateTokenV2 />}>
            <Route path="/FMS" element={<FMSContainerPageV2 />}>
              <Route index path="Dashboard" element={<FMSDashboardPage />} />
              <Route path="Vessel" element={<FMSVesselPage />} >
                <Route path="VesselDetail" element={<FMSVesselDetailPage />} />
                <Route path="VesselTracking" element={<FMSVesselTrackingPage />} />
                <Route path="VesselSMS" element={<FMSVesselSMSPage />} />
                <Route path="VesselSMS/EnginePerformance" element={<FMSEnginePerformancePage />} />
                <Route path="Dashboard" element={<FMSVesselSMSDashboard />}/>
              </Route>

              <Route path="Map" element={<FMSMapPage />} />
              <Route path="Maintenance" element={<FMSMaintenancePage />} />
              <Route path="Crew" element={<FMSCrewPage />} />
              <Route path="Inventory" element={<FMSInventoryPage />} />
              <Route path="Analytics" element={<FMSAnalyticsPage />} />
            </Route>
          </Route>
          */}
          
          <Route path="/FMS" element={<FMSContainerPage />} />
          
          <Route path="/FMS/Admin" element={<FMSAdminLogin />} />
          <Route path="/FMS/Admin/Dashboard" element={<AdminContainerPage />} />
          
          
          {/* <Route path="/FMS/Admin/Login" element={<FMSAdminLogin />} />
          <Route element={<AuthRoute callbackURL="/FMS/Admin/Login" permittedRoles={[RoutePermittedRole.ADMINISTRATOR, RoutePermittedRole.SUPER_ADMINISTRATOR, RoutePermittedRole.FMS_ADMINISTRATOR]} />}>
            <Route path="/FMS/Admin/" element={<AdminContainerPageV2 />}>
              <Route index path="Dashboard" element={<AdminContainerPage />} />
            </Route>
          </Route> */}

          <Route path="/Unauthorized" element={<Box sx={{width: '100%', height: '100vh', backgroundColor: 'white'}}>Unauthorized</Box>} />
        
          {/* <Route path="/FMS/Admin/Customers" element={<AdminCustomerPage />} />
          <Route path="/FMS/Admin/Products" element={<AdminProductPage />} />
          <Route path="/FMS/Admin/Vessels" element={<AdminVesselPage />} /> */}
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
}
