import './index.css'
import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import { isTokenValid, getVesselTrackingData, getDistanceFromLatLonInKm, getVesselEngineDataForVesselTracking } from "../../../../Helpers/ApplicationHelpers";
import "../../../AppV2.css";
import { AccountCircle, Key, Login, Visibility, VisibilityOff } from "@mui/icons-material";
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import FMSTopBar from "../../../../Components/v2/FMSTopBar";
import FMSTheme from "../../../FMSTheme";
import FMSSidebar from '../../../../Components/v2/FMSSidebar';
import EnhancedTable from '../../../../Components/v2/FMSTable';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import { Trans, useTranslation } from "react-i18next";
import NavBar from "../../../../Components/v2/FMSNavBarMUI";
import OpenSeaMap from "../../../../Components/v2/FMSOpenSeaMapTracking/index";
// import OpenSeaMap from "../../../../Components/OpenSeaMapContainer";
import { parseNmeaSentence } from "nmea-simple";
import { MapContainer, Marker, Popup, TileLayer, useMap, rotatedMarker } from "react-leaflet";
import moment from "moment";
import DmsCoordinates from "dms-conversion";
import RotatedMarker from "../../../../Components/RotatedMarker";
import vesselImg from "../../../../Assets/Images/new_arrow_pointer.png";
import vesselImg2 from "../../../../Assets/Images/new_arrow_pointer_green.png";
import { Box, Card, Button, Typography, TextField, MenuItem } from "@mui/material";
import Paper from '@mui/material/Paper';
import Collapse from '@mui/material/Collapse';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import DropDownForm from "../../../../Components/DropDownForm";
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import { Form } from 'react-bootstrap';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Loading from "../../../../Components/v2/FMSLoading/index";
import PrintReportTracking from "../VesselTracking/PrintReportTracking/index";
import ReactDOM from "react-dom/client"; 
import { vesselTrackingGenerateReportPDF } from '../../../../Helpers/ApplicationHelpers';
import { findAllVesselById } from '../../../../Helpers/ApplicationHelpers';
import { getCompanyLogo } from '../../../../Helpers/ApplicationHelpers';
export default function FMSVesselTrackingPage({ state }) {
    const { t, i18n } = useTranslation();
    let location = useLocation();
    const [cookies, removeCookie] = useCookies(["token"]);
    const [gpsData, setGPSData] = useState([]);
    const [listNMEAData, setListNMEAData] = useState([]);
    const [engineAndLocationData, setEngineAndLocationData] = useState();
    const [selectedNMEAData, setSelectedNMEAData] = useState();
    const [listVesselData, setListVesselData] = useState();
    const [listLineData, setListLineData] = useState();
    const [markerData, setMarkerData] = useState([]);
    const [vesselId, setVesselId] = useState("");
    const [vesselName, setVesselName] = useState("");
    const navigate = useNavigate();
    const [selectStartDate, setSelectStartDate] = useState();
    const [selectEndDate, setSelectEndDate] = useState();
    const [checked, setChecked] = useState(false);
    const [checkedSummary, setCheckedSummary] = useState(false);
    const [processedData, setProcessedData] = useState();
    const [startTime, setStartTime] = useState();
    const [endTime, setEndTime] = useState();
    const [totalDuration, setTotalDuration] = useState();
    const [totalDistance, setTotalDistance] = useState(0);
    const [averageSpeed, setAverageSpeed] = useState();
    const [averageMEP, setAverageMEP] = useState();
    const [averageMES, setAverageMES] = useState();
    const [averageThrottleP, setAverageThrottleP] = useState();
    const [averageThrottleS, setAverageThrottleS] = useState();
    const [loading, setLoading] = useState(false);
    const [isPrint, setIsPrint] = useState(false);
    const [printState, setPrintState] = useState();
    // const [peripheralDeviceTypeName, setPeripheralDeviceTypeName] = useState('')
    const [historicalData, setHistoricalData] = useState([]);
    const [isBackupGPSUsed, setIsBackupGPSUsed] = useState(false);

    const durationOption = [
        {
            label: "1 Hour Ago",
            value: 1,
        },
        {
            label: "1 Day Ago",
            value: 2,
        },
        {
            label: "1 Week Ago",
            value: 3,
        },
    ];

    const handleChange = () => {
        setChecked((prev) => !prev);
        setCheckedSummary(false);
    };

    const handleChangeSummary = () => {
        setChecked(false);
        setCheckedSummary((prev) => !prev);
    };

    

    const icon = (
        <Paper sx={{ m: 1, width: 100, height: 100 }} elevation={4}>
            <svg>
                <Box
                    component="polygon"
                    points="0,100 50,00, 100,100"
                    sx={(theme) => ({
                        fill: theme.palette.common.white,
                        stroke: theme.palette.divider,
                        strokeWidth: 1,
                    })}
                />
            </svg>
        </Paper>
    );


    useEffect(() => {
        setVesselId(state?.vesselId);
        setVesselName(state?.vesselName);
    }, []);

    useEffect(() => {
        if (isPrint){
            if (isPrint === true){
                const print = {
                    vesselId: vesselId,
                    startTime: startTime,
                    endTime: endTime,
                    totalDuration: totalDuration,
                    totalDistance: totalDistance,
                    averageMEP: averageMEP,
                    averageMES: averageMES,
                    averageThrottleP: averageThrottleP,
                    averageThrottleS: averageThrottleS,
                }
                setPrintState(print);
            }
        }
    }, [isPrint])

    useEffect(() => {
        console.log("PRINT STATE ", printState);
    }, [printState])

    useEffect(() => {
        console.log('isPrint', isPrint);
        console.log('print state', printState);
    }, [printState])


    useEffect(() => {
        if (markerData) {
            setLoading(false);
            // if (markerData.length > 0) {

            // }
        }
    }, [markerData])

    // useEffect(() => {
    //     console.log('selectStartDate', selectStartDate);
    // }, [selectStartDate])

    useEffect(() => {
        console.log('processedData', processedData);
        if (processedData) {
            if (processedData.length > 0) {

            } else {

            }
        }

    }, [processedData])


    useEffect(() => {
        if (engineAndLocationData) {
            console.log(engineAndLocationData);
            if (engineAndLocationData.length > 0) {
                let listMarker = [];
                let lastMarker = null;
                let listLine = [];
                let mepRPMArray = [];
                let idxForMEPRPM = 0;
                let idxForMESRPM = 0;
                let idxForMEPThrottle = 0;
                let idxForMESThrottle = 0;
                let totalMEPRPM = 0;
                let totalMESRPM = 0;
                let totalMEPThrottle = 0;
                let totalMESThrottle = 0;
                let idxForDistance = 0;
                let idxForSpeed = 0;
                let totalDistance = 0;
                let totalSpeed = 0;
                let idx = 0;
                engineAndLocationData.forEach((data, index) => {
                    if (data.latitude && data.longitude) {
                        if(data.mep_throttle){
                            const temp = parseFloat(data.mep_throttle);
                            if(temp < 0){
                                data.mep_throttle = 0;
                            }
                        }

                        if(data.mes_throttle){
                            const temp = parseFloat(data.mes_throttle);
                            if(temp < 0){
                                data.mes_throttle = 0;
                            }
                        }
                        let timestampGPS = "N/A";
                        if(data.read_date){
                            timestampGPS = moment.utc(data.read_date).local().format("YYYY-MM-DD HH:mm")
                        }
                        const dmsCoords = new DmsCoordinates(data.latitude, data.longitude);
                        const cogOffset = 0;
                        const speedKnots = data.speed;
                        let markerEl =
                            <RotatedMarker key={index} position={[data.latitude, data.longitude]} icon={data.is_backup ? vesselIcon2 : vesselIcon} rotationAngle={
                                data.cog > 359.9 ? (data.cog - 359.9).toFixed(1) : data.cog
                            } rotationOrigin='center' timeStamp={timestampGPS} speed={parseFloat(data.speed)}
                                rpmME={[parseFloat(data.mep_rpm), parseFloat(data.mes_rpm)]}
                                throttleME={[parseFloat(data.mep_throttle), parseFloat(data.mes_throttle)]}>
                                <Popup>
                                    <div className="popupContainerVesselMap">
                                        <p className="pForPopupVessel">
                                            {vesselName}<br />
                                            <hr />
                                        </p>
                                        <p className="pForPopupVesselBody">
                                            <b>POSITION ({data.is_backup ? "BACKUP" : "AIS"})</b><br />
                                            {`${dmsCoords.latitude.dmsArray[0]}°${dmsCoords.latitude.dmsArray[1]}'${dmsCoords.latitude.dmsArray[2].toFixed(4)}" ${dmsCoords.latitude.dmsArray[3]}` }&emsp;
                                            {`${dmsCoords.longitude.dmsArray[0]}°${dmsCoords.longitude.dmsArray[1]}'${dmsCoords.longitude.dmsArray[2].toFixed(4)}" ${dmsCoords.longitude.dmsArray[3]}` }
                                        </p>
                                        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <p className="pForPopupVesselBody">
                                                <b>SPEED</b><br />
                                                {data.is_backup ? "N/A" : speedKnots} knots<br />
                                                <div className="dividerforPopupVesselLocation"></div><br />
                                                <b>MAIN ENGINE</b><br />
                                                PORT<br />
                                                {data.mep_rpm !== null ? (parseFloat(data.mep_rpm)).toFixed(0) + ' RPM' : 'N/A'}<br />
                                                <div className="dividerforPopupVesselLocation"></div><br />
                                                <b>THROTTLE</b><br />
                                                PORT<br />
                                                {data.mep_throttle !== null ? (parseFloat(data.mep_throttle)).toFixed(1) + '%' : 'N/A'}<br />
                                            </p>
                                            <p className="pForPopupVesselBody" style={{ textAlign: 'right' }}>
                                                <b>COG</b><br />
                                                { data.is_backup ? "N/A" : 
                                                    (parseFloat(data.cog) + parseFloat(cogOffset)).toFixed(1) > 359.9 ? ((parseFloat(data.cog) + parseFloat(cogOffset)) - 359.9).toFixed(1)
                                                        : (parseFloat(data.cog) + parseFloat(cogOffset)).toFixed(1)
                                                } °<br />
                                                <div className="dividerforPopupVesselLocation"></div><br />
                                                <b>MAIN ENGINE</b><br />
                                                STARBOARD<br />
                                                {data.mes_rpm !== null ? (parseFloat(data.mes_rpm)).toFixed(0) + ' RPM' : 'N/A'}<br />
                                                <div className="dividerforPopupVesselLocation"></div><br />
                                                <b>THROTTLE</b><br />
                                                STARBOARD<br />
                                                {data.mes_throttle !== null ? (parseFloat(data.mes_throttle)).toFixed(1) + '%' : 'N/A'}<br />
                                            </p>
                                        </div>
                                        <hr />
                                        <p className="pForPopupVesselBody">
                                            <b>Time Stamp:</b> {timestampGPS}
                                        </p>
                                    </div>
                                </Popup>
                            </RotatedMarker>;

                        if (lastMarker !== null) {
                            let distance = getDistanceFromLatLonInKm(lastMarker.props.position[0], lastMarker.props.position[1], markerEl.props.position[0], markerEl.props.position[1]);
                            let speed = lastMarker.props.speed;
                            let MEPrpm = lastMarker.props.rpmME[0];
                            let MESrpm = lastMarker.props.rpmME[1];
                            let MEPthrottle = lastMarker.props.throttleME[0];
                            let MESthrottle = lastMarker.props.throttleME[1];
                            console.log("Distance: ", distance);
                            if (!isNaN(distance)) {
                                totalDistance += distance;
                                idxForDistance++;
                            }
                            if (!isNaN(speed)) {
                                totalSpeed += speed;
                                idxForSpeed++;
                            }
                            if (!isNaN(MEPrpm)) {
                                totalMEPRPM += MEPrpm;
                                idxForMEPRPM++;
                            }
                            if (!isNaN(MESrpm)) {
                                totalMESRPM += MESrpm;
                                idxForMESRPM++;
                            }
                            if (!isNaN(MEPthrottle)) {
                                totalMEPThrottle += MEPthrottle;
                                idxForMEPThrottle++;
                            }
                            if (!isNaN(MESthrottle)) {
                                totalMESThrottle += MESthrottle;
                                idxForMESThrottle++;
                            }

                        }

                        listLine.push({is_backup: data.is_backup, marker: markerEl});
                        console.log(listLine);
                        //console.log("MARKER", markerChild.listMarker[0].props);

                        if (!lastMarker) {
                            listMarker.push({is_backup: data.is_backup, marker: markerEl});
                            lastMarker = markerEl;
                        } else {
                            console.log(lastMarker.props);
                            if (Math.abs(parseFloat(lastMarker.props.rotationAngle) - parseFloat(markerEl.props.rotationAngle)) > 10) {
                                listMarker.push({is_backup: data.is_backup, marker: markerEl});
                            }
                        }

                        idx++;
                        lastMarker = markerEl;
                    }

                })
                console.log('PANJANG', listLine);
                let lastIndexFromListMarker = listLine.length - 1;
                //console.log
                let startTime = moment(listLine[0].marker.props.timeStamp);
                let endTime = moment(listLine[lastIndexFromListMarker].marker.props.timeStamp);
                let durationMs = endTime.diff(startTime);
                let formattedDuration = moment.utc(durationMs).format("HH:mm:ss");
                let averageSpeed = totalSpeed / idxForSpeed + 1;
                let averageMEP = totalMEPRPM / idxForMEPRPM + 1;
                let averageMES = totalMESRPM / idxForMESRPM + 1;
                let averageThrottleP = totalMEPThrottle / idxForMEPThrottle + 1;
                let averageThrottleS = totalMESThrottle / idxForMESThrottle + 1;
                console.log("Duration", formattedDuration);
                console.log("Total Distance: ", totalDistance);
                setStartTime(startTime);
                setEndTime(endTime);
                setTotalDuration(formattedDuration);
                setTotalDistance(totalDistance);
                setAverageSpeed(averageSpeed)
                setAverageMEP(averageMEP);
                setAverageMES(averageMES);
                setAverageThrottleP(averageThrottleP);
                setAverageThrottleS(averageThrottleS);

                let markerObj = {
                    listVessel: [],
                    listMarker: listMarker,
                    listLine: listLine
                }
                setMarkerData(markerObj);
                // setIsDataReady(true);
                // setLoading(false);
            } else {
                let listMarker = [];
                let markerObj = {
                    listVessel: [],
                    listMarker: listMarker,
                    listLine: []
                }
                setMarkerData(markerObj);
                // setIsDataReady(true);
                // setLoading(false);
            }
        }
    }, [engineAndLocationData])

    // const processEngineAndLocationData = (objData) => {
    //     try {
    //         const packet = parseNmeaSentence(objData.nmea_sentence.replace("\r", "\r\n"));

    //         if (packet.sentenceId === "RMC" && packet.status === "valid") {
    //             return {
    //                 ...objData,
    //                 latitude: packet.latitude,
    //                 longitude: packet.longitude,
    //                 cog: packet.trackTrue,
    //                 speed: packet.speedKnots,
    //                 timestamp_gps: objData.read_date
    //             };
    //         } else {
    //             console.error("Invalid or unsupported NMEA sentence:", objData.nmea_sentence);
    //         }
    //     }
    //     catch (exception) {
    //         console.log(exception);
    //     }
    //     return objData;

    // }

    let vesselIcon = L.icon({
        iconUrl: vesselImg,
        iconRetinaUrl: vesselImg,
        popupAnchor: [0, -30],
        iconSize: [30, 30],
    });

    let vesselIcon2 = L.icon({
        iconUrl: vesselImg2,
        iconRetinaUrl: vesselImg2,
        popupAnchor: [0, -30],
        iconSize: [30, 30],
    });

    const loadHistorisCoordinate = async () => {
        try {
            setLoading(true);
            const result = await getVesselTrackingData(cookies.token, vesselId, moment(selectEndDate).format("YYYY-MM-DD HH:mm:ssZ"), moment(selectStartDate).format("YYYY-MM-DD HH:mm:ssZ"));
            setHistoricalData(result);
            setEngineAndLocationData(result);
        } catch (exception) {
            console.error(exception);
        } finally {
            setLoading(false);
        }
    };

    const loadEngineVesselData = async () => {
        try {
            setLoading(true);
            let result = await getVesselEngineDataForVesselTracking(cookies.token, vesselId, isBackupGPSUsed);
            // console.log(result);
            if (result) {
                let newArr = [];
                for (let i = 0; i < result.length; i++) {
                    const vesselData = {
                        latitude: result[i].latitude,
                        longitude: result[i].longitude,
                        cog: result[i].cog,
                        speed: result[i].speed,
                        speedKnots: result[i].speed,
                        mep_rpm: result[i].mep_rpm,
                        mes_rpm: result[i].mes_rpm,
                        mep_throttle: result[i].mep_throttle,
                        mes_throttle: result[i].mes_throttle,
                        read_date : result[i].read_date,
                        timestamp_gps: moment.utc(result[i].read_date).local().format("YYYY-MM-DD HH:mm"),
                        cogOffset: '0',
                        is_backup: result[i].is_backup
                    };
                    newArr.push(vesselData);
                }
                // console.log(newArr);
                setEngineAndLocationData(newArr);
            }
        }
        catch (exception) {
            console.log(exception);
        }
    }

    const handleSwitchData = (useBackupGPS) => {
        setIsBackupGPSUsed(useBackupGPS);
        // const deviceType = useBackupGPS ? "Backup GPS" : "AIS";
        // const selectedDevice = historicalData.find(p => p.peripheralDeviceTypeName === deviceType);

        // if (selectedDevice && selectedDevice.listLocation) {
        //     setEngineAndLocationData(selectedDevice.listLocation);
        // } else {
        //     setEngineAndLocationData([]);
        // }
    };


    const handlePrint = async () => {
        try{
            const printWindow = window.open("", "_blank", "width=800,height=600");
            if (printWindow) {
                setLoading(true);
                const startDateString = moment(startTime).format("yyyy-MM-DD HH:mm:ssZ");
                const endDateString = moment(endTime).format("yyyy-MM-DD HH:mm:ssZ");
                const trackingReport = await vesselTrackingGenerateReportPDF(cookies.token, vesselId, startDateString, endDateString);
                const vesselData = await findAllVesselById(cookies.token, vesselId);
                const resCompanyLogo = await getCompanyLogo(cookies.token, cookies.extCustomerId);
                setLoading(false);
                // Write initial HTML structure
                printWindow.document.write(`
                    <!DOCTYPE html>
                    <html lang="en">
                    <head>
                        <meta charset="UTF-8">
                        <meta name="viewport" content="width=device-width, initial-scale=1.0">
                        <title>Print Report</title>
                    </head>
                    <body>
                        <div id="print-root"></div>
                    </body>
                    </html>
                `);
    
                printWindow.document.close(); // Ensure the document is ready
                const lastEngineAndLocationData = engineAndLocationData.at(-1);
                let latitude = "N/A";
                let longitude = "N/A";
                if(lastEngineAndLocationData){
                    const dmsCoords = new DmsCoordinates(lastEngineAndLocationData.latitude, lastEngineAndLocationData.longitude);
                    latitude = `${dmsCoords.latitude.dmsArray[0]}°${dmsCoords.latitude.dmsArray[1]}'${dmsCoords.latitude.dmsArray[2].toFixed(4)}" ${dmsCoords.latitude.dmsArray[3]}`;
                    longitude = `${dmsCoords.longitude.dmsArray[0]}°${dmsCoords.longitude.dmsArray[1]}'${dmsCoords.longitude.dmsArray[2].toFixed(4)}" ${dmsCoords.longitude.dmsArray[3]}`;
                }
                const print = {
                    vesselId: vesselId,
                    vesselName: vesselName,
                    startTime: startTime,
                    endTime: endTime,
                    totalDuration: totalDuration,
                    totalDistance: totalDistance * 0.59,
                    averageMEP: averageMEP,
                    averageMES: averageMES,
                    averageThrottleP: averageThrottleP,
                    averageThrottleS: averageThrottleS,
                    trackingReport: trackingReport,
                    vesselData: vesselData,
                    companyLogo: resCompanyLogo,
                    latitude: latitude,
                    longitude: longitude
                }
                // Render the React component inside the new window
                const printRoot = ReactDOM.createRoot(printWindow.document.getElementById("print-root"));
                printRoot.render(
                    <React.StrictMode>
                        <PrintReportTracking state={print} />
                    </React.StrictMode>
                );

                printWindow.onbeforeunload = () => {
                    console.log("Print window is closing...");
                    printRoot.unmount();
                };

                printWindow.onafterprint = () => {
                    console.log("After print");
                    
                    setTimeout(() => {
                        printWindow.close();
                    }, 5000);
                };
    
                // Trigger print after rendering
                setTimeout(() => {
                    if (printWindow && !printWindow.closed) {
                        printWindow.print();
                    }
                }, 4000);
            }
        }catch(exception){
            console.error(exception);
        }
    };


    return (
        <>
            <div className='mainContainerFMS'>
                <div className='childMainContainerFMS'>
                    <div className='childMainContainerFMS-BODY' style={{ position: 'relative' }}>
                        <div style={{ position: 'absolute', top: '150px', left: '10px', zIndex: 1000 }}>
                            <Box
                                sx={{
                                    width: 40,
                                    height: 40,
                                    borderRadius: 1,
                                    bgcolor: '#1E3A8A',
                                    ml: 1,
                                    boxShadow: 5
                                }}
                            >
                                <IconButton aria-label="delete" size="medium" sx={{
                                    color: 'white'
                                }} onClick={handleChange}>
                                    <SearchIcon fontSize="inherit" />
                                </IconButton>
                            </Box>

                            <Box
                                sx={{
                                    '& > :not(style)': {
                                        display: 'flex',
                                        justifyContent: 'space-around',
                                        height: 120,
                                        width: 250,
                                    },
                                }}
                            >
                                <div style={{ marginLeft: '60px', marginTop: '50px' }}>
                                    <Collapse in={checked}>
                                        <div className="collapseContainerVesselTracking">
                                            <div className='collapseContainerVesselTrackingBody'>
                                                <Box sx={{ width: '100%', margin: '5px' }}>
                                                    <TextField
                                                        style={{ width: '100%' }}
                                                        label="Start Date"
                                                        type="datetime-local"
                                                        fullWidth
                                                        variant="outlined"
                                                        InputLabelProps={{ shrink: true }}
                                                        onChange={(e) => {
                                                            const localDateTime = moment(e.target.value, "YYYY-MM-DD HH:mm:ss");
                                                            setSelectStartDate(localDateTime.format("YYYY-MM-DD HH:mm:ss"));
                                                        }}
                                                        value={selectStartDate ? moment(selectStartDate).format("YYYY-MM-DD HH:mm:ss") : ""}
                                                    />
                                                </Box>

                                                {/* End Date Field */}
                                                <Box sx={{ width: '100%', margin: '5px' }}>
                                                    <TextField
                                                        style={{ width: '100%' }}
                                                        label="End Date"
                                                        type="datetime-local"
                                                        fullWidth
                                                        variant="outlined"
                                                        InputLabelProps={{ shrink: true }}
                                                        onChange={(e) => {
                                                            const localDateTime = moment(e.target.value, "YYYY-MM-DD HH:mm:ss");
                                                            setSelectEndDate(localDateTime.format("YYYY-MM-DD HH:mm:ss"));
                                                        }}
                                                        value={selectEndDate ? moment(selectEndDate).format("YYYY-MM-DD HH:mm:ss") : ""}
                                                    />
                                                </Box>

                                                {/* Duration Dropdown */}
                                                <Box sx={{ width: '100%', margin: '5px' }}>
                                                    <TextField
                                                        style={{ width: '100%' }}
                                                        select
                                                        label="Duration"
                                                        variant="outlined"
                                                        onChange={(e) => {
                                                            // console.log(e)
                                                            let dateTime = new Date();
                                                            if (e.target.value === 1) {
                                                                const startDate = moment(dateTime).subtract(60, "minutes").format("YYYY-MM-DD HH:mm");
                                                                const endDate = moment(dateTime).format("YYYY-MM-DD HH:mm");
                                                                setSelectStartDate(startDate);
                                                                setSelectEndDate(endDate);
                                                            } else if (e.target.value === 2) {
                                                                const startDate = moment(dateTime).subtract(1, "day").format("YYYY-MM-DD HH:mm");
                                                                const endDate = moment(dateTime).format("YYYY-MM-DD HH:mm");
                                                                setSelectStartDate(startDate);
                                                                setSelectEndDate(endDate);
                                                            } else if (e.target.value === 3) {
                                                                const startDate = moment(dateTime).subtract(1, "week").format("YYYY-MM-DD HH:mm");
                                                                const endDate = moment(dateTime).format("YYYY-MM-DD HH:mm");
                                                                setSelectStartDate(startDate);
                                                                setSelectEndDate(endDate);
                                                            }
                                                        }}
                                                    >
                                                        {durationOption.map((option) => (
                                                            <MenuItem key={option.value} value={option.value}>
                                                                {option.label}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </Box>
                                                <Box sx={{ width: '100%', margin: '5px' }}>
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                checked={isBackupGPSUsed}
                                                                onChange={(e) => handleSwitchData(e.target.checked)}
                                                            />
                                                        }
                                                        label="Use Backup GPS"
                                                    />
                                                </Box>
                                                <Box sx={{ width: '100%', margin: '5px' }}>
                                                    <Button
                                                        style={{ width: '100%' }}
                                                        variant="contained"
                                                        fullWidth
                                                        onClick={() => {
                                                            loadHistorisCoordinate();
                                                        }}
                                                    >
                                                        Find Vessel Tracking Data
                                                    </Button>
                                                </Box>
                                                <Box sx={{ width: '100%', margin: '5px' }}>
                                                    <Button
                                                        style={{ width: '100%' }}
                                                        variant="contained"
                                                        fullWidth
                                                        onClick={() => {
                                                            loadEngineVesselData();
                                                        }}
                                                    >
                                                        Current Position
                                                    </Button>
                                                </Box>
                                            </div>
                                        </div>
                                    </Collapse>
                                </div>
                            </Box>
                        </div>

                        <div style={{ position: 'absolute', top: '200px', left: '10px', zIndex: 1000 }}>
                            <Box
                                sx={{
                                    width: 40,
                                    height: 40,
                                    borderRadius: 1,
                                    bgcolor: '#1E3A8A',
                                    ml: 1,
                                    boxShadow: 5
                                }}
                            >
                                <IconButton aria-label="delete" size="medium" sx={{
                                    color: 'white'
                                }} onClick={handleChangeSummary}>
                                    <StickyNote2Icon fontSize="inherit" />
                                </IconButton>
                            </Box>

                            <Box
                                sx={{
                                    '& > :not(style)': {
                                        display: 'flex',
                                        justifyContent: 'space-around',
                                        height: 120,
                                        width: 250,
                                    },
                                }}
                            >
                                <div style={{ marginLeft: '60px', marginTop: '0px' }}>
                                    <Collapse in={checkedSummary}>
                                        <div className="collapseContainerVesselTracking">
                                            <div className='collapseContainerVesselTrackingBody'>
                                                <div style={{ flex: 3, width: '100%', height: '100%' }}>
                                                    <CardContent>
                                                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                            <Typography gutterBottom sx={{ color: 'text.primary', fontSize: 18 }}>
                                                                Tracking Summary
                                                            </Typography>
                                                        </div>
                                                        <hr />
                                                        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                            <Typography gutterBottom sx={{ color: 'text.primary', fontSize: 14 }}>
                                                                Start
                                                            </Typography>
                                                            <Typography gutterBottom sx={{ color: 'text.primary', fontSize: 14 }}>
                                                                End
                                                            </Typography>
                                                        </div>
                                                        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                            <Typography gutterBottom sx={{ color: 'text.primary', fontSize: 14 }}>
                                                                {moment.utc(startTime).local().format('YYYY-MM-DD HH:mm')}
                                                            </Typography>
                                                            <Typography gutterBottom sx={{ color: 'text.primary', fontSize: 14 }}>
                                                                {moment.utc(endTime).local().format('YYYY-MM-DD HH:mm')}
                                                            </Typography>
                                                        </div>
                                                        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                            <Typography gutterBottom sx={{ color: 'text.primary', fontSize: 14 }}>
                                                                Duration
                                                            </Typography>
                                                            <Typography gutterBottom sx={{ color: 'text.primary', fontSize: 14 }}>
                                                                Distance
                                                            </Typography>
                                                        </div>
                                                        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                            <Typography gutterBottom sx={{ color: 'text.primary', fontSize: 14 }}>
                                                                {totalDuration}
                                                            </Typography>
                                                            <Typography gutterBottom sx={{ color: 'text.primary', fontSize: 14 }}>
                                                                {(parseFloat(totalDistance) * 0.59).toFixed(2)} NM
                                                            </Typography>
                                                        </div>
                                                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                            <Typography gutterBottom sx={{ color: 'text.primary', fontSize: 18 }}>
                                                                Average RPM
                                                            </Typography>
                                                        </div>
                                                        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                            <Typography gutterBottom sx={{ color: 'text.primary', fontSize: 14 }}>
                                                                Port
                                                            </Typography>
                                                            <Typography gutterBottom sx={{ color: 'text.primary', fontSize: 14 }}>
                                                                Starboard
                                                            </Typography>
                                                        </div>
                                                        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                            <Typography gutterBottom sx={{ color: 'text.primary', fontSize: 14 }}>
                                                                {!isNaN(averageMEP)  ? parseFloat(averageMEP).toFixed(2) : "N/A"} RPM
                                                            </Typography>
                                                            <Typography gutterBottom sx={{ color: 'text.primary', fontSize: 14 }}>
                                                                {!isNaN(averageMES)  ? parseFloat(averageMES).toFixed(2) : "N/A"} RPM
                                                            </Typography>
                                                        </div>

                                                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                            <Typography gutterBottom sx={{ color: 'text.primary', fontSize: 18 }}>
                                                                Average Throttle
                                                            </Typography>
                                                        </div>
                                                        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                            <Typography gutterBottom sx={{ color: 'text.primary', fontSize: 14 }}>
                                                                Port
                                                            </Typography>
                                                            <Typography gutterBottom sx={{ color: 'text.primary', fontSize: 14 }}>
                                                                Starboard
                                                            </Typography>
                                                        </div>
                                                        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                            <Typography gutterBottom sx={{ color: 'text.primary', fontSize: 14 }}>
                                                                {!isNaN(averageThrottleP)  ? parseFloat(averageThrottleP).toFixed(2) : "N/A"} %
                                                            </Typography>
                                                            <Typography gutterBottom sx={{ color: 'text.primary', fontSize: 14 }}>
                                                                {!isNaN(averageThrottleS)  ? parseFloat(averageThrottleS).toFixed(2) : "N/A"} %
                                                            </Typography>
                                                        </div>
                                                        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                            <Box sx={{ width: '100%', margin: '5px' }}>
                                                                <Button
                                                                    disabled={!startTime || !endTime}
                                                                    style={{ width: '100%' }}
                                                                    variant="contained"
                                                                    fullWidth
                                                                    onClick={() => {
                                                                        handlePrint();
                                                                    }}
                                                                >
                                                                    Print Tracking SUmmary
                                                                </Button>
                                                            </Box>
                                                        </div>

                                                        
                                                    </CardContent>
                                                </div>
                                            </div>
                                        </div>
                                    </Collapse>
                                </div>
                            </Box>
                        </div>
                        {
                            markerData && <OpenSeaMap markerChild={markerData} isTracking={true} isBackupGPSUsed={isBackupGPSUsed}/>
                        }
                    </div>
                </div>
                <div className='loadingContainerVesselMapFMS'>
                    <Loading
                        loading={loading}
                        loadingText={`LOADING`}
                    />
                </div>
                {
                    isPrint && printState !== null && <PrintReportTracking state={printState}/>
                }
            </div>
        </>
    );

}
