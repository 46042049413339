import './index.css';
import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { isFMSAdminTokenValid, findAllCustomerAdminPage } from "../../../../Helpers/ApplicationHelpers";
import "../../../AppV2.css";
import { Trans, useTranslation } from "react-i18next";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow'; 


export default function AdminCustomerPage() {
    const { t, i18n } = useTranslation();
    const [cookies, removeCookie] = useCookies(["token"]);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [page, setPage] = useState(1);        // 1-based page indexing
    const [pageSize, setPageSize] = useState(10);
    const [error, setError] = useState(null);

    const totalPages = Math.ceil(totalCount / pageSize);

    useEffect(() => {
        const fetchData = async () => {
        setLoading(true);
        setError(null);
        try {
            const response = await fetch(`/api/users?page=${page}&pageSize=${pageSize}`);
            if (!response.ok) {
            throw new Error('Failed to fetch data');
            }
            const json = await response.json();
            setData(json.data);
            setTotalCount(json.totalCount);
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
        };

        fetchData();
    }, [page, pageSize]);

    const handlePageChange = (event, newPage) => {
        setPage(newPage);
    };

    const handlePageSizeChange = (event) => {
        setPageSize(event.target.value);
        setPage(1); // reset to page 1 on page size change
    };

    return (
        <Box p={2}>
        <h2>Server-Side Pagination with MUI</h2>

        {loading && (
            <Box display="flex" justifyContent="center" my={2}>
            <CircularProgress />
            </Box>
        )}

        {error && (
            <Box color="red" my={2}>
            {error}
            </Box>
        )}

        <TableContainer component={Paper} variant="outlined">
            <Table aria-label="server-side table">
            <TableHead>
                <TableRow>
                {/* Adjust columns based on your data */}
                <TableCell>ID</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {!loading && data.length === 0 && (
                <TableRow>
                    <TableCell colSpan={3} align="center">
                    No data available
                    </TableCell>
                </TableRow>
                )}
                {data.map((row) => (
                <TableRow key={row.id}>
                    <TableCell>{row.id}</TableCell>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.email}</TableCell>
                </TableRow>
                ))}
            </TableBody>
            </Table>
        </TableContainer>

        {/* Pagination and Page Size Controls */}
        <Box display="flex" alignItems="center" justifyContent="space-between" mt={2}>
            <Pagination
            count={totalPages}
            page={page}
            onChange={handlePageChange}
            color="primary"
            />

            <FormControl variant="outlined" size="small">
            <InputLabel>Rows per page</InputLabel>
            <Select
                label="Rows per page"
                value={pageSize}
                onChange={handlePageSizeChange}
            >
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
            </Select>
            </FormControl>
        </Box>
        </Box>
    );

    useEffect(() => {

    }, []);

    const loadCustomer = async () => {
        try {
            let result = await getCustomerV2(cookies.token, cookies.extCustomerId);
            console.log(result);
            setListCustomer(result);
        }
        catch (exception) {
            console.log(exception);
        }
    }


    return (
        <>
            <div className='mainContainerFMSAdmin'>
                <div className='childMainContainerFMSAdmin'>
                    <div className='childMainContainerFMSAdmin-BODY'>
                        <div className='fmsEnhancedTableContainerAdmin'>
                            <EnhancedTable data={listCustomer} columns={columnsForTable}/>
                        </div>
                    </div>
                </div>
            </div>
            <Loading
                loading={loading}
                loadingText={`LOADING`}
                />
        </>
    );
}