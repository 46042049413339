import './index.css';
import React, { useEffect, useState, useRef } from "react";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { IconButton, InputAdornment, Link } from "@mui/material";
import axios from "axios";
import config from "../../../Config/Config";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { isTokenValid, getVesselV2 } from "../../../Helpers/ApplicationHelpers";
import { alpha } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import "../../AppV2.css";
import { AccountCircle, Key, Login, Visibility, VisibilityOff } from "@mui/icons-material";
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import FMSTopBar from "../../../Components/v2/FMSTopBar";
import FMSTheme from "../../FMSTheme";
import FMSSidebar from '../../../Components/v2/FMSSidebar';
import EnhancedTable from '../../../Components/v2/FMSTableForVessel';
import { Trans, useTranslation } from "react-i18next";
import NavBar from "../../../Components/v2/FMSNavBarMUI";
import FMSAuthenticateToken from '../../../Components/v2/FMSAuthenticateToken';
import Loading from "../../../Components/v2/FMSLoading/index";

export default function FMSVesselPage({setPageToDisplay, setPageTitle}) {
    const { t, i18n } = useTranslation();
    const [cookies, removeCookie] = useCookies(["token"]);
    const [listVessel, setListVessel] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const columnsForTable = [
        { id: 'img_button', label: '' },
        { id: 'sms_serial_number', label: 'Serial Number' },
        { id: 'vessel_name', label: 'Vessel Name' },
        { id: 'vessel_type_name', label: 'Vessel Type' },
        // { id: 'class_register_number', label: 'Class' },
        { id: 'imo_number', label: 'IMO Number' },
        { id: 'tracking_button', label: 'Tracking' },
        { id: 'smsdashboard_button', label: 'Telemetry' },
        // { id: 'maintenance_button', label: 'Maintenance' },
        { id: 'shipparticular_button', label: 'Detail' },
        { id: 'equipment_button', label: 'Equipment' },
        { id: 'certificate_button', label: 'Document' },
        { id: 'vessel_status', label: 'Status'},
    ];

    useEffect(() => {
        loadVessel();
    }, []);

    useEffect(() => {
        console.log('list vessel', listVessel);
        if (listVessel){
            if (listVessel.length > 0){
                setLoading(false);
            }
            
        }
        
    }, [listVessel])

    const loadVessel = async () => {
        try {
            let result = await getVesselV2(cookies.token, cookies.languageId, cookies.extCustomerId);
            // console.log(result);
            setListVessel(result);
        }
        catch (exception) {
            console.log(exception);
        }
    }

    return (
        <>
            <div className='mainContainerFMS'>
                <div className='childMainContainerFMS'>
                    <div className='childMainContainerFMS-BODY'>
                        <div className='fmsEnhancedTableContainer'>
                            <EnhancedTable data={listVessel} columns={columnsForTable} setPageToDisplay={setPageToDisplay} setPageTitle={setPageTitle}/>
                        </div>
                    </div>
                </div>
            </div>
            <Loading
                loading={loading}
                loadingText={`LOADING`}
                />
        </>
    );
}