import { useContext, createContext, useState } from "react";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [data, setData] = useState(null);
  const [role, setRole] = useState(null);

  const login = (data, role) => {
    console.log("LOGGING IN");
    setData(data);
    setRole(role);
  };

  const logout = () => {
    setData(null);
    setRole(null);
  };

  return (
    <AuthContext.Provider value={{ data, role, login, logout }}>
      {children}
    </AuthContext.Provider>
  );

};

export default AuthProvider;

export const useAuth = () => {
  return useContext(AuthContext);
};
