import React, { useEffect, useState, Suspense } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import "../../../AppV2.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { isTokenValid, findVesselByVesselId } from "../../../../Helpers/ApplicationHelpers";
import FMSTheme from "../../../FMSTheme";
import NavBar from "../../../../Components/v2/FMSNavBarMUI";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Container } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import './index.css'

const FMSMainEngine = React.lazy(() => import("./MainEngine"));
const FMSAuxiliaryEngine = React.lazy(() => import("./AuxiliaryEngine"));
const FMSGearBox = React.lazy(() => import("./GearBox"));
const FMSMainEnginePerformance = React.lazy(() => import("./EnginePerformance"));
const FMSAuxiliaryEnginePerformance = React.lazy(() => import("./AuxiliaryEnginePerformance"));
const FMSFuelConsumption= React.lazy(() => import("./FuelConsumption"));
const FMSTankLevel= React.lazy(() => import("./TankLevel"));

import { findAllVesselById } from "../../../../Helpers/ApplicationHelpers";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

export default function FMSVesselSMSDashboard({state}) {
    const { t, i18n } = useTranslation();
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const [cookies, removeCookie] = useCookies(["token"]);
    const navigate = useNavigate();
    let location = useLocation();
    const [vesselId, setVesselId] = useState();
    // const state = location.state;
    
    // List of tabs and corresponding components
    const tabs = [
        { label: "Main Engine", Component: FMSMainEngine, State: {vesselId: state.vesselId}},
        { label: "Auxiliary Engine", Component: FMSAuxiliaryEngine, State: {vesselId: state.vesselId} },
        { label: "Gearbox", Component: FMSGearBox, State: {vesselId: state.vesselId} },
        { label: "Tank Level", Component: FMSTankLevel, State: {vesselId: state.vesselId} },
        { label: "Main Engine Performance", Component: FMSMainEnginePerformance, State: {vesselId: state.vesselId} },
        { label: "Auxiliary Engine Performance", Component: FMSAuxiliaryEnginePerformance, State: {vesselId: state.vesselId} },
        { label: "Fuel Consumption", Component: FMSFuelConsumption, State: {vesselId: state.vesselId} },
    ];

    useEffect(() => {
        setVesselId(state.vesselId);
    }, []);

    useEffect(() => {
        if(vesselId){
            loadVessel();
        }
    }, [vesselId]);

    const handleChange = (event, newValue) => {
        console.log("TAB", newValue);
        setSelectedTabIndex(newValue);
    };

    const loadVessel = async () => {
        try {
            let result = await findAllVesselById(cookies.token, vesselId);
            setVessel(result);
        }
        catch (exception) {
            console.log(exception);
        }
    }

    return (
        <div className='mainContainerFMS'>
            <div className='childMainContainerFMS'>
                <div className='childMainContainerFMS-BODY overflow-auto'>
                    <Box
                        sx={{
                            bgcolor: "background.paper",
                            display: "flex",
                            width: "100%",
                            border: 1,
                            borderColor: "divider",
                        }}
                        className="customScrollbarTabs"
                    >
                        <Tabs
                            orientation="vertical"
                            variant="scrollable"
                            value={selectedTabIndex}
                            onChange={handleChange}
                            aria-label="Vertical tabs example"
                            sx={{ borderRight: 1, borderColor: "divider" }}
                        >
                            {tabs.map((tab, index) => (
                                <Tab key={index} label={tab.label} />
                            ))}
                        </Tabs>
                        <Container fluid>
                            {tabs.map((tab, index) => (
                                <TabPanel value={selectedTabIndex} index={index} key={index}>
                                    <Suspense fallback={<div>Loading...</div>}>
                                        {selectedTabIndex === index && <tab.Component state={tab.State}/>}
                                    </Suspense>
                                </TabPanel>
                            ))}
                        </Container>
                    </Box>
                </div>
            </div>
        </div>
    );
}
