import './index.css';
import React, { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { isTokenValid, } from "../../../Helpers/ApplicationHelpers";
import "../../AppV2.css";
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import FMSTheme from "../../FMSTheme";
import { Trans, useTranslation } from "react-i18next";
import NavBar from "../../../Components/v2/FMSNavBarMUI";
import Loading from "../../../Components/v2/FMSLoading/index";
import FMSVesselDashboardContainer from '../../../Components/v2/FMSVesselDashboardContainer';
import { Button, Card, Collapse, FormControl, Grid2, Icon, MenuItem, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material';
import { getDashboardDataV2 } from '../../../Helpers/ApplicationHelpers';
import { Grid, Box, Typography, Paper, CircularProgress } from "@mui/material";
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import GaugeComponent from '../../../Components/v2/FMSGauge';

import { getVesselV2 } from '../../../Helpers/ApplicationHelpers';
import DynamicLineChartDoubleAxis from '../../../Components/v2/FMSChart';
import { Grid1x2 } from 'react-bootstrap-icons';
import FMSAuthenticateToken from '../../../Components/v2/FMSAuthenticateToken';

import { getDistanceFromLatLonInKm } from '../../../Helpers/ApplicationHelpers';
import { getVesselTrackingData } from '../../../Helpers/ApplicationHelpers';
import moment from "moment";
import noImg from "../../../Assets/Images/FMS/vessel_fms_icon.png";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Col, Container } from 'react-bootstrap';
import { getVesselNMEAV2 } from '../../../Helpers/ApplicationHelpers';


import { parseNmeaSentence } from 'nmea-simple';
import { CollectionsBookmarkOutlined } from '@mui/icons-material';
import FMSVesselSMSDashboard from '../FMSVessel/VesselSMSDashboard';
import FMSVesselTrackingPage from '../FMSVessel/VesselTracking';

import convert from 'convert-units';

export default function FMSDashboardPage({setPageToDisplay, setPageTitle}) {

    const TableRowVessel = React.memo(({ vessel }) => {
        return (
            <TableRow hover key={vessel.vesselId}>
                <TableCell>{vessel.vesselName}</TableCell>
                    <TableCell>{vessel.shipTotalDistance}</TableCell>
                    <TableCell>{vessel.shipTotalFuelConsumption}</TableCell>
                    <TableCell style={{color: (vessel.statusVesselActive) ? "green" : "red"}}>{vessel.latestReadDate}</TableCell>
                    <TableCell className={vessel.statusColor}>{vessel.statusText}</TableCell>
                    <TableCell className="text-danger">{vessel.alarmsActive.map((v) => {
                        return <>{v.master_coordinate_description.slice(5)}<br/></>;
                    })}</TableCell>
                    <TableCell>
                        <Button style={{ marginRight: '15px' }} onClick={() => {
                            setPageToDisplay(<FMSVesselSMSDashboard state={{vesselId: vessel.vesselId}} />);
                            setPageTitle(`${vessel.vesselName || "DASHBOARD"} | ${vessel.vesselIMO || "VESSEL"}`);
                        }}><Icon size={40} color="#64748B">speed</Icon>
                        </Button>
                    </TableCell>
                    <TableCell>
                        <Button style={{ marginRight: '15px' }} onClick={() => {
                            setPageToDisplay(<FMSVesselTrackingPage state={{vesselId: vessel.vesselId, vesselName: vessel.vesselName}} />);
                            setPageTitle(`${vessel.vesselName || "VESSEL"} | ${vessel.vesselIMO || ""} TRACKING`);
                        }}><Icon size={40} color="#64748B">pin_drop</Icon>
                        </Button>
                    </TableCell>
            </TableRow>
        );
      });
    
    
    const { t, i18n } = useTranslation();
    const [cookies, removeCookie] = useCookies(["token"]);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const [listVessel, setListVessel] = useState([]);

    const [listParameter, setListParameter] = useState({});
    const [totalDistance, setTotalDistance] = useState(0);
    const [totalFuelConsumption, setTotalFuelConsumption] = useState(0);

    const datetimeFormat = "yyyy-MM-DD HH:mm:ssZ";

    const [selectStartDate, setSelectStartDate] = useState(
        moment().subtract(1, "week")
    );

    const [selectEndDate, setSelectEndDate] = useState(
        moment()
    );

    const [showData, setShowData] = useState(false);

    const [durationValue, setDurationValue] = useState(3);

    const [tableToRender, setTableToRender] = useState([]);
    const addVessel = useCallback((newVessel) => {
        setTableToRender((prevTable) => [...prevTable, newVessel]);
      }, []);



    const durationOption = [
        {
            label: "1 Hour Ago",
            value: 1,
            duration: {
                number: 60,
                unit: "minutes",
            }
        },
        {
            label: "1 Day Ago",
            value: 2,
            duration: {
                number: 1,
                unit: "day",
            }
        },
        {
            label: "1 Week Ago",
            value: 3,
            duration: {
                number: 1,
                unit: "week",
            }
        },
        {
            label: "1 Month Ago",
            value: 4,
            duration: {
                number: 1,
                unit: "month",
            }
        },
        {
            label: "3 Months Ago",
            value: 5,
            duration: {
                number: 3,
                unit: "months",
            }
        },
    ];


    useEffect(() => {
        // loadVessel();
    }, []);

    useEffect(() => {
        if(showData){
            setLoading(true);
        }else{
            setLoading(false);
        }
    }, [showData])

    useEffect(() => {
        if(listVessel?.length > 0){
            console.log("Test");
            listVessel.forEach((value, index) => {
                loadParametersValue(value.vessel_id, value.vessel_name, value.imo_number, value.status_vessel_active);
            });
        }
    }, [listVessel]);

    useEffect(() => {
        console.log("TABLE TO RENDER");
        console.log(tableToRender);
    }, [tableToRender])

    useEffect(() => {
        if(durationValue > 0){
            if(showData){
                loadVessel();
                
            }
        }
    }, [selectStartDate, selectEndDate, showData]);

    useEffect(() => {
        setTableToRender([]);
        if (durationValue) {
            try {
                let dateTime = new Date();
                let startDate = "";
                let endDate = "";
                const selectedOption = durationOption.find(option => option.value == durationValue);
                console.log(selectedOption);
                if (selectedOption) {
                    const { number, unit } = selectedOption.duration;
                    startDate = moment(dateTime).subtract(number, unit);
                    endDate = moment(dateTime);
                    setSelectStartDate(startDate);
                    setSelectEndDate(endDate);
                    setShowData(true);
                }
            } catch (exception) {
                console.log(exception);
            }
        }

    }, [durationValue]);

    useEffect(() => {

    }, [listParameter]);

    const durationSelectHandler = (e) => {
        setListVessel([]);
        setTotalDistance(0);
        setTotalFuelConsumption(0);
        setDurationValue(e.target.value);
    }

    const loadVessel = async () => {
        try {
            let result = await getVesselV2(cookies.token, cookies.languageId, cookies.extCustomerId);
            console.log(result);
            setListVessel(result);
        }
        catch (exception) {
            console.log(exception);
        }
    }

    useEffect(() => {
        console.log("list param : ",listParameter);
    }, [listParameter])

    useEffect(() => {
        console.log("TOTAL DISTANCE ", totalDistance);
    }, [totalDistance])


    const appendToTableToRender = (vesselId, vesselName, vesselIMO, statusVesselActive, dashboardData) => {
        const parameter = dashboardData;
        const portME = parameter?.data?.port?.me;
        const starboardME = parameter?.data?.starboard?.me;
        const portAE = parameter?.data?.port?.ae;
        const starboardAE = parameter?.data?.starboard?.ae;

        const status = parameter?.status;
        const alarm = parameter?.alarm;

        console.log("portME : ", portME);
        console.log("starboardME : ", starboardME);

        

        const portMETotalFuelMax = portME?.totalFuelUsage?.max_reading_value || 0;
        const portMETotalFuelMin = portME?.totalFuelUsage?.min_reading_value || 0;

        const portAETotalFuelMax = portAE?.totalFuelUsage?.max_reading_value || 0;
        const portAETotalFuelMin = portAE?.totalFuelUsage?.min_reading_value || 0;
        const portTotalFuelConsumed = (portMETotalFuelMax - portMETotalFuelMin) + (portAETotalFuelMax - portAETotalFuelMin);

        const starboardMETotalFuelMax = starboardME?.totalFuelUsage?.max_reading_value || 0;
        const starboardMETotalFuelMin = starboardME?.totalFuelUsage?.min_reading_value || 0;

        const starboardAETotalFuelMax = starboardAE?.totalFuelUsage?.max_reading_value || 0;
        const starboardAETotalFuelMin = starboardAE?.totalFuelUsage?.min_reading_value || 0;
        const starboardTotalFuelConsumed = (starboardMETotalFuelMax - starboardMETotalFuelMin) + (starboardAETotalFuelMax - starboardAETotalFuelMin);
        
        let latestReadDate = parameter?.lastReadDate;
        latestReadDate = latestReadDate ? moment(latestReadDate).format("YYYY-MM-DD HH:mm:ss") : "";
        const shipTotalFuelConsumption = portTotalFuelConsumed + starboardTotalFuelConsumed;
        const shipTotalDistance = parameter?.distance || 0;

        const alarmsActive = getActiveAlarms(alarm);
        const statusMoving = isVesselMoving(status);

        let statusColor = statusMoving ? "text-warning" : "text-success";
        let statusText = statusMoving ? "moving" : "idle";

        const newData = {
            "vesselId" : vesselId,
            "vesselName" : vesselName,
            "shipTotalDistance" : parseFloat(shipTotalDistance).toFixed(2), 
            "shipTotalFuelConsumption" : parseFloat(shipTotalFuelConsumption).toFixed(2),
            "latestReadDate" : latestReadDate || 'N/A',
            "statusColor" : statusColor,
            "statusText" : statusText,
            "alarmsActive" : alarmsActive,
            "vesselName" : vesselName,
            "vesselIMO" : vesselIMO,
            "statusVesselActive" : statusVesselActive
        };

        addVessel(newData);

        setTotalDistance((prevTotalDistance) => prevTotalDistance + shipTotalDistance);
        setTotalFuelConsumption((prevTotalFuel) => prevTotalFuel + shipTotalFuelConsumption);
    }

    const loadParametersValue = async (vesselId, vesselName, vesselIMO, statusVesselActive) => {
        try {
            
            const dashboardData = await getDashboardDataV2(
                cookies.token, cookies.languageId, vesselId, selectStartDate.format(datetimeFormat), selectEndDate.format(datetimeFormat));
            const vesselTrackingData = await getVesselNMEAV2(cookies.token, cookies.languageId, vesselId, selectStartDate.format(datetimeFormat), selectEndDate.format(datetimeFormat));
            const totalDistanceInNM = calculateTotalDistance(vesselTrackingData || false);

            dashboardData["distance"] = isNaN(totalDistanceInNM)? 0 : totalDistanceInNM;
            
            appendToTableToRender(vesselId, vesselName,  vesselIMO, statusVesselActive, dashboardData);
            
            // const newListParameter = { ...listParameter, [vesselId]: dashboardData };
            // setListParameter(newListParameter);
        }catch (exception) {
            console.error(exception);
        }
        setShowData(false);
    }

    const calculateTotalDistance = (vesselTrackingData) => {
        let totalDistanceInKM = 0;
            
        if (vesselTrackingData) {
            let newArr = [];
            for (let i = 0; i < vesselTrackingData.length; i++) {
                const updatedData = processEngineAndLocationData(vesselTrackingData[i]);
                if(updatedData !== null){
                    newArr.push(updatedData);
                }
            }
            let prevLon = null;
            let prevLat = null;
            
            newArr.forEach((data) => {
                
                let currentLon = data.longitude;
                let currentLat = data.latitude;
                if(prevLon !== null && prevLat !== null){
                    totalDistanceInKM += getDistanceFromLatLonInKm(prevLat,prevLon,currentLat,currentLon)
                    // console.log(totalDistanceInKM);
                }
                prevLon = currentLon;
                prevLat = currentLat;
                
            });
        }

        return convertKMToNauticalMiles(totalDistanceInKM);
    }

    const convertKMToNauticalMiles = (totalKM) => {
        return totalKM * 0.5399568035;
    }

    // const renderVessels = (vessel) => {
    //     const vesselRows = listVessel.map((v, index) => {
    //         const vesselId = v.vessel_id;
    //         const vesselName = v.vessel_name;
    //         const vesselIMO = v.imo_number;
    //         console.log("LIST PARAMETER VESSEL ID : ",vesselId, listParameter);
    //         const parameter = listParameter[vesselId];
    //         const portME = parameter?.data?.port?.me;
    //         const starboardME = parameter?.data?.starboard?.me;
    //         const portAE = parameter?.data?.port?.ae;
    //         const starboardAE = parameter?.data?.starboard?.ae;

    //         const status = parameter?.status;
    //         const alarm = parameter?.alarm;
    
    //         console.log("portME : ", portME);
    //         console.log("starboardME : ", starboardME);

            
    
    //         const portMETotalFuelMax = portME?.totalFuelUsage?.max_reading_value || 0;
    //         const portMETotalFuelMin = portME?.totalFuelUsage?.min_reading_value || 0;

    //         const portAETotalFuelMax = portAE?.totalFuelUsage?.max_reading_value || 0;
    //         const portAETotalFuelMin = portAE?.totalFuelUsage?.min_reading_value || 0;
    //         const portTotalFuelConsumed = (portMETotalFuelMax - portMETotalFuelMin) + (portAETotalFuelMax - portAETotalFuelMin);
    
    //         const starboardMETotalFuelMax = starboardME?.totalFuelUsage?.max_reading_value || 0;
    //         const starboardMETotalFuelMin = starboardME?.totalFuelUsage?.min_reading_value || 0;

    //         const starboardAETotalFuelMax = starboardAE?.totalFuelUsage?.max_reading_value || 0;
    //         const starboardAETotalFuelMin = starboardAE?.totalFuelUsage?.min_reading_value || 0;
    //         const starboardTotalFuelConsumed = (starboardMETotalFuelMax - starboardMETotalFuelMin) + (starboardAETotalFuelMax - starboardAETotalFuelMin);
            
    //         let latestReadDate = parameter?.lastReadDate;
    //         latestReadDate = latestReadDate ? moment(latestReadDate).format("YYYY-MM-DD HH:mm:ss") : "";
    //         const shipTotalFuelConsumption = portTotalFuelConsumed + starboardTotalFuelConsumed;
    //         const shipTotalDistance = parameter?.distance || 0;

    //         const alarmsActive = getActiveAlarms(alarm);
    //         const statusMoving = isVesselMoving(status);

    //         let statusColor = statusMoving ? "text-warning" : "text-success";
    //         let statusText = statusMoving ? "moving" : "idle";

            
    //         return  ();
    //     })
    //     return vesselRows;
    // }

    const getActiveAlarms = (alarm) => {
        const listAlarms = [
            ...Object.values(alarm?.port.me || {}), 
            ...Object.values(alarm?.port.ae || {}), 
            ...Object.values(alarm?.starboard.me || {}), 
            ...Object.values(alarm?.starboard.ae || {})
        ];

        let activeAlarms = [];
        listAlarms.forEach((alarm) => {
            const status = JSON.parse(alarm.reading_value?.toLowerCase() || "");
            if(status === true){
                activeAlarms.push(alarm);
            }
        });

        return activeAlarms;
    };

    const isVesselMoving = (status) => {
        if(!status){return false}
        const portGB = status.port.gb;
        const starboardGB = status.starboard.gb;
        const portME = status.port.me;
        const starboardME = status.starboard.me;

        const rpmThresHold = 200;

        Object.values(portGB).forEach(direction => {
            const directionStatus = JSON.parse(direction.reading_value?.toLowerCase() || "");
            if(directionStatus === true){
                if(parseFloat(portME.rpm.reading_value) > rpmThresHold){
                    return true;
                }
            }
        });

        Object.values(starboardGB).forEach(direction => {
            const directionStatus = JSON.parse(direction.reading_value?.toLowerCase() || "");
            if(directionStatus === true){
                if(parseFloat(starboardME.rpm.reading_value) > rpmThresHold){
                    return true;
                }
            }
        });

        return false;
    }


    const processEngineAndLocationData = (objData) => {
        try {
            const packet = parseNmeaSentence(objData.nmea_sentence.replace("\r", "\r\n"));

            if (packet.sentenceId === "RMC" && packet.status === "valid") {
                return {
                    ...objData,
                    latitude: packet.latitude,
                    longitude: packet.longitude
                };
            } else {
                console.error("Invalid or unsupported NMEA sentence:", objData.nmea_sentence);
                return null;
            }
        }
        catch (exception) {
            console.log(exception);
            return null;
        }
        return objData;
    }

    return (
        <>
            <div className='mainContainerFMS'>
                <div className='childMainContainerFMS'>
                    <div className='childMainContainerFMS-BODY overflow-auto'>
                        <Container fluid>
                            {/* <Stack spacing={2}>
                                {listVessel?.map((v) => {
                                    return <div key={v?.vessel_id}>{renderVessel(v?.vessel_id, v?.base64_image)}</div>;
                                })}
                            </Stack> */}
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: 2,
                                    p: 2,
                                    minHeight: '100vh',
                                }}
                                >
                                {/* Date Selector */}
                                <FormControl fullwidth disabled={loading}>
                                    <Select 
                                        id="select-duration"
                                        value={durationValue}
                                        onChange={durationSelectHandler}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem value='0'>Select Time...</MenuItem>
                                        {durationOption.map((option, index) => (
                                            <MenuItem key={index} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </Select>  
                                </FormControl>

                                {/* Total Voyage Section */}
                                <Box
                                    sx={{
                                    border: '1px solid #ccc',
                                    borderRadius: 1,
                                    padding: 2,
                                    }}
                                >
                                    <Typography variant="h6">TOTAL VOYAGE</Typography>
                                    <Typography variant="body1" sx={{ fontWeight: 'bold', mb: 1 }}>
                                    {parseFloat(totalDistance).toFixed(2)} NM
                                    </Typography>
                                    <Typography variant="h6">TOTAL FUEL CONSUMPTION</Typography>
                                    <Typography variant="body1" sx={{ fontWeight: 'bold', mb: 1 }}>
                                    {parseFloat(totalFuelConsumption).toFixed(2)} Liter
                                    </Typography>
                                    <Box
                                        sx={{
                                            border: '1px solid #ccc',
                                            borderRadius: 1,
                                            padding: 2,
                                        }}
                                        >
                                        <Typography variant="h6" mb={2}>LIST VESSEL</Typography>
                                        <TableContainer component={Paper}>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell><strong>Vessel Name</strong></TableCell>
                                                        <TableCell><strong>Distance (NM)</strong></TableCell>
                                                        <TableCell><strong>Total Fuel Consumption (L)</strong></TableCell>
                                                        <TableCell><strong>Read Date</strong></TableCell>
                                                        <TableCell><strong>Status</strong></TableCell>
                                                        <TableCell><strong>On Going Alarm</strong></TableCell>
                                                        <TableCell><strong>Telemetry Dashboard</strong></TableCell>
                                                        <TableCell><strong>Tracking</strong></TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {tableToRender.map((vessel) => {
                                                        return <TableRowVessel key={vessel?.vesselId} vessel={vessel} />;
                                                    })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Box>
                                </Box>
                            </Box>
                        </Container>
                    </div>
                </div>
            </div>
            <Loading
                loading={loading}
                loadingText={`LOADING`}
                />
        </>
    );
}
