import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import FMSAuthenticate from '../FMSAuthenticate';
import { isTokenValid } from '../../../Helpers/ApplicationHelpers';
import { Outlet } from 'react-router-dom';

const FMSAuthenticateTokenV2 = () => {
    const [cookies] = useCookies(["token"]);

    const params = {
        validationFunc: isTokenValid, // Function to validate the token
        validationData: cookies.token, // Token retrieved from cookies
        navigateURL: "/" // Redirect URL if validation fails
    };
    
    return (
        <FMSAuthenticate params={params}>
            <Outlet />
        </FMSAuthenticate>
    );
};

export default FMSAuthenticateTokenV2;