import './index.css';
import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { isFMSAdminTokenValid } from "../../../../Helpers/ApplicationHelpers";
import "../../../AppV2.css";
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import FMSTheme from "../../../FMSTheme";
import { Trans, useTranslation } from "react-i18next";
import NavBar from "../../../../Components/v2/FMSAdminNavBarMUI";
import FMSAdminAuthenticateToken from '../../../../Components/v2/FMSAdminAuthenticateToken';
import Loading from "../../../../Components/v2/FMSLoading/index";

export default function AdminProductsPage() {
    const { t, i18n } = useTranslation();
    const [cookies, removeCookie] = useCookies(["token"]);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isFMSAdminTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/FMS");
            else {
                if (cookies.language_code === "id-id") {
                    i18n.changeLanguage("id");
                } else if (cookies.language_code === "en-us") {
                    i18n.changeLanguage("en");
                }
            }
        }
        checkCookies();
    }, []);



    return (
        <>
            <div className='mainContainerFMSAdmin'>
                <div className='childMainContainerFMSAdmin'>
                    <div className='childMainContainerFMSAdmin-BODY'>
                        {/* <div className='fmsEnhancedTableContainer'>
                            <EnhancedTable data={listVessel} columns={columnsForTable} setPageToDisplay={setPageToDisplay} setPageTitle={setPageTitle}/>
                        </div> */}
                    </div>
                </div>
            </div>
            <Loading
                loading={loading}
                loadingText={`LOADING`}
                />
        </>
    );
}